import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper/types";
import { FreeMode, Navigation, Thumbs, Scrollbar } from "swiper";

import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/bundle";

import "./styles.scss";

interface Props {
  images: string[];
}

const ProductGalerySlider = ({ images }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();

  return (
    <section className="swiper-section-wrapper" aria-label="slide">
      <Swiper
        loop={images.length > 5}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="galeryMainSlider"
      >
        {images.map((image) => (
          <SwiperSlide key={image}>
            <img src={image} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={5}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs, Scrollbar]}
        className="galeryThumbSlider"
        scrollbar={{
          enabled: true,
          lockClass: "scroll",
          draggable: true
        }}
      >
        {images.map((image) => (
          <SwiperSlide key={image}>
            <img src={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ProductGalerySlider;

import { IProductImageProps } from "@/interfaces/products";

export function allProductImages(images: IProductImageProps[], styleName: string) {
    const urlImages: string[] = [];

    for (const img of images) {
        for (const st of img.styles) {
            if (st.style == styleName) {
                urlImages.push(st.url);
            }
        }
    }
    return urlImages;
};

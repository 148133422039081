import React, { ReactNode, useEffect, useRef } from 'react';
import './style.scss';

interface FloatingCTAProps {
  children: ReactNode;
  enableFloatingCTA: boolean;
}

const FloatingCTA = (props: FloatingCTAProps) => {
  const { children, enableFloatingCTA } = props;
  const documentRef = useRef<Document | null>(null);
  const windowRef = useRef<Window | null>(null);
  const ctaButtonContainerRef = useRef<HTMLDivElement | null>(null);
  const ctaButtonRef = useRef<HTMLDivElement | null>(null);

  const classFixedName = 'floating-cta__container--fixed';
  const showAnimationClass = 'floating-cta__container--show-animation';
  const hideAnimationClass = 'floating-cta__container--hide-animation';
  const bodyVariable = '--sticky-cta-height';

  const calculateScrollSpeed = (speedCB: (speed: number) => void) => {
    let lastScrollY = 0;
    let ticking = false;
    let scrollSpeed = 0;

    const updateScrollSpeed = () => {
      const currentScrollY = windowRef.current!.scrollY;
      scrollSpeed = Math.abs(currentScrollY - lastScrollY);
      lastScrollY = currentScrollY;
      speedCB(scrollSpeed);
    };

    const requestTick = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateScrollSpeed();
          ticking = false;
        });
      }
      ticking = true;
    };

    windowRef.current!.addEventListener('scroll', requestTick);
  };

  const setButtonContainerHeight = () => {
    // Check if the sticky button height changes
    // create an Observer instance
    const resizeObserver = new ResizeObserver((entries) => {
      const currentButtonHeight = entries[0].target.clientHeight;
      const currentContainerHeight =
        ctaButtonContainerRef.current?.clientHeight || 0;
      if (currentButtonHeight > currentContainerHeight) {
        ctaButtonContainerRef.current!.style.height = `${
          currentButtonHeight - 10
        }px`;
      }
    });

    // start observing a DOM node
    resizeObserver.observe(ctaButtonRef.current!);
  };

  const changeBodyStickyVariable = (action: 'add' | 'remove') => {
    if (action === 'add') {
      documentRef.current?.body.style.setProperty(
        bodyVariable,
        `${ctaButtonRef.current?.clientHeight || 0 - 10}px`
      );
    } else {
      documentRef.current?.body.style.setProperty(bodyVariable, '0px');
    }
  };

  const handleHideAnimationEnd = () => {
    ctaButtonRef.current?.classList.remove(classFixedName, hideAnimationClass);
    ctaButtonRef.current?.removeEventListener(
      'animationend',
      handleHideAnimationEnd
    );
  };

  const fixButton = () => {
    ctaButtonRef.current?.classList.add(classFixedName, showAnimationClass);
    changeBodyStickyVariable('add');
    ctaButtonRef.current?.removeEventListener(
      'animationend',
      handleHideAnimationEnd
    );
  };

  const unfixButton = (cssAnimationSpeed: string) => {
    ctaButtonRef.current?.classList.remove(showAnimationClass);
    ctaButtonRef.current?.classList.add(hideAnimationClass);
    ctaButtonRef.current?.style.setProperty(
      '--animation-speed',
      cssAnimationSpeed
    );
    changeBodyStickyVariable('remove');
    ctaButtonRef.current?.addEventListener(
      'animationend',
      handleHideAnimationEnd
    );
  };

  const toggleFloatingCTA = () => {
    let cssAnimationSpeed = '0.2s';

    calculateScrollSpeed((scrollSpeed) => {
      const animationSpeed = 1 / scrollSpeed;
      const minSpeed = 0;
      const maxSpeed = 0.2;

      cssAnimationSpeed = `${Math.min(
        Math.max(animationSpeed, minSpeed),
        maxSpeed
      )}s`;
    });

    // create an Observer instance to check if the sticky button is in view
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          fixButton();
        } else if (entry.isIntersecting) {
          unfixButton(cssAnimationSpeed);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0,
      }
    );

    // start observing the sticky button
    observer.observe(ctaButtonContainerRef.current!);
  };

  useEffect(() => {
    documentRef.current = document;
    windowRef.current = window;
    if (enableFloatingCTA) {
      setButtonContainerHeight();
      toggleFloatingCTA();
    }
  }, []);

  return (
    <div ref={ctaButtonContainerRef} className="floating-cta__container">
      <div ref={ctaButtonRef} className="floating-cta__button">
        {children}
      </div>
    </div>
  );
};

export default FloatingCTA;

import React, { useEffect } from 'react';
import { track } from '@/shared/analytics';

import shareIcon from '@/static/images/share.svg';
import { allProductImages } from '@/lib/productImages';
import { IPageContext } from '@/interfaces/pageContext';
import { DefaultLayout } from 'src/layouts/DefaultLayout';
import { IFinalProductProps } from '@/interfaces/products';
import ProductGalerySlider from '@/components/ProductGalerySlider';
import securePaymentIcon from '@/static/images/secure_payment.svg';
import { currencyFormat } from '@/lib/CurrencyFormat';
import { PaymentMethodsComponent } from '@/components/PaymentMethods';
import HowItWorks from '@/components/HowItWorks';
import { DecideHomeDisplay } from 'src/templates/Home/utils';
import ProductCard from '@/components/ProductCard';
import {
  hasPropertiesWithValue,
  hasProperty,
  mountUrl,
} from '@/shared/helpers';

import './style.scss';
import FloatingCTA from '@/components/FloatingCTA';

type Props = {
  pageContext: IPageContext & {
    product: IFinalProductProps;
  };
};

const ProductDetails = ({ pageContext }: Props) => {
  const { product, appData, pathLang } = pageContext;
  const { templateData, paymentMethods, products, locale } = appData;
  const { home, general } = templateData;
  const detailLocale = locale.product_detail;
  const genericLocale = locale.generics;
  const peopleViewing = Math.round(Math.random() * (10 - 1) + 2);
  const productsCta = templateData.products.productCta || '';
  const cartAddCta = templateData.products.cartAddCta || '';
  const otherProducts = products.filter((p) => p.id !== product.id).slice(0, 6);
  const paymentMethodCreditCard = paymentMethods.find(
    ({ code, active }) => code === 'credit_card' && active
  );
  const { type } = DecideHomeDisplay(home.homeDisplay || 'default');
  const howItWorksData = templateData.products.productHowItWorks;
  const underPrice = home.productList.homeProductListUnderPrice;

  const defaultLayoutData = {
    pageContext,
    templateData,
    title: pageContext.product.name!,
    display: type,
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);

    const messageLinkCopied = document.querySelector(
      '.message-link-copied'
    ) as HTMLElement | null;

    if (messageLinkCopied) {
      const element = messageLinkCopied.style;

      element.display = 'block';

      setTimeout(() => {
        element.display = 'none';
      }, 2000);
    }
  };

  useEffect(() => {
    track('productPageView', {
      template: 'virgoA',
      productName: product.name,
      productId: product.id,
    });
  }, []);

  return (
    <DefaultLayout {...defaultLayoutData}>
      <article className="product-detail-container">
        <header>
          {product.images.length > 0 ? (
            <div
              role="region"
              aria-label="product image slide"
              className="product-detail-slide"
            >
              <ProductGalerySlider
                images={allProductImages(product.images, 'medium')}
              />
            </div>
          ) : null}
          <div
            role="region"
            aria-label="product info"
            className="product-detail-info"
          >
            <div className="product-title-description-wrapper">
              <div className="product-title">
                <h1>{product.name}</h1>
                <span className="message-link-copied">
                  {detailLocale.message_link_copied}
                </span>
                <img
                  onClick={copyToClipboard}
                  src={shareIcon}
                  alt="share icon"
                />
              </div>

              <div
                className="product-description"
                dangerouslySetInnerHTML={{
                  __html: product.description!,
                }}
              />
            </div>

            <div className="product-payment-info-wrapper">
              <div className="product-payment-info">
                <div className="price-info">
                  {product.currentTariff?.oldPriceCents ? (
                    <div className="product-with-old-price">
                      <p className="product-old-price">
                        {locale.product_detail.from}{' '}
                        <span>
                          R${' '}
                          {currencyFormat(product.currentTariff.oldPriceCents)}
                        </span>
                      </p>
                      <p>
                        {general.from || locale.product_detail.to}{' '}
                        <span className="product-price">
                          R${' '}
                          {currencyFormat(product.currentTariff?.priceCents!)}
                        </span>{' '}
                        {underPrice}
                      </p>
                    </div>
                  ) : (
                    <div className="product-without-old-price">
                      <span>
                        {locale.product_detail.from_without_old_price}
                      </span>
                      <p className="product-price">
                        R$ {currencyFormat(product.currentTariff?.priceCents!)}
                      </p>
                      {underPrice ? <span>{underPrice}</span> : null}
                    </div>
                  )}
                  <div className="product-installments">
                    {paymentMethodCreditCard &&
                    paymentMethodCreditCard.maxInstallmentsNum! >
                      paymentMethodCreditCard.interestRateExemptedInstallments &&
                    paymentMethodCreditCard.maxInstallmentsNum! > 1 ? (
                      <>
                        <p>
                          {detailLocale.up_to}{' '}
                          {paymentMethodCreditCard.maxInstallmentsNum}
                          {detailLocale.maximum_installment_with_interest}
                        </p>
                        {!templateData.products.hideMinimumInstallment ? (
                          <p>
                            ({detailLocale.minimum_installment} R${' '}
                            {currencyFormat(
                              paymentMethodCreditCard?.minInstallmentAmountCents!
                            )}
                            )
                          </p>
                        ) : null}
                      </>
                    ) : null}

                    {paymentMethodCreditCard &&
                    paymentMethodCreditCard.maxInstallmentsNum! <=
                      paymentMethodCreditCard.interestRateExemptedInstallments &&
                    paymentMethodCreditCard.maxInstallmentsNum! > 1 ? (
                      <>
                        <p>
                          {detailLocale.up_to}{' '}
                          {paymentMethodCreditCard.maxInstallmentsNum}
                          {detailLocale.interest_free_installments}
                        </p>
                        {!templateData.products.hideMinimumInstallment ? (
                          <p>
                            ({detailLocale.minimum_installment} R${' '}
                            {currencyFormat(
                              paymentMethodCreditCard?.minInstallmentAmountCents!
                            )}
                            )
                          </p>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <figure className="product-payment-methods">
                    <PaymentMethodsComponent
                      paymentMethods={pageContext.appData.paymentMethods}
                    />
                  </figure>
                </div>
              </div>
              <div className="product-adder-button">
                <FloatingCTA
                  enableFloatingCTA={!templateData.products.mobileFloatCTA}
                >
                  <div
                    className="planne-widget planne-widget-product-adder"
                    data-widget-product-id={product.id}
                    data-widget-enable-modal="true"
                    data-widget-cart-add-cta-text={cartAddCta}
                    data-widget-product-cta-text={productsCta}
                  />
                </FloatingCTA>
              </div>
              <div className="product-secure-payment-message">
                <img src={securePaymentIcon} alt="secure payment icon" />
                <p>{genericLocale.secure_payment_ensurance}</p>
              </div>
              <div className="product-people-viewing">
                <strong>
                  {peopleViewing} {detailLocale.people}
                </strong>{' '}
                <span>{detailLocale.viewing_page}</span>
              </div>
            </div>
          </div>
        </header>

        <div className="divisor-container ">
          <div className="divisor" />
        </div>

        {hasProperty(product, [
          'includedItems',
          'notIncludedItems',
          'voucherGuidelines',
        ]) ? (
          <div
            role="region"
            aria-label="info-of-product"
            className="product-informations"
          >
            <h1>{detailLocale.product_informations}</h1>

            {product.includedItems && product.notIncludedItems ? (
              <div className="product-included-and-not-included">
                {product.includedItems && product.includedItems.length > 0 ? (
                  <div className="included-container">
                    <h2>{detailLocale.included}</h2>

                    <ul>
                      {product.includedItems.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                <div className="included-and-not-included-divisor" />

                {product.notIncludedItems &&
                product.notIncludedItems.length > 0 ? (
                  <div className="not-included-container">
                    <h2>{detailLocale.not_included}</h2>
                    <ul>
                      {product.notIncludedItems.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div
              className="product-voucher-description"
              dangerouslySetInnerHTML={{
                __html: product.voucherGuidelines!,
              }}
            />
          </div>
        ) : null}

        {hasPropertiesWithValue(howItWorksData) ? (
          <HowItWorks
            title={howItWorksData.title}
            content={howItWorksData.content}
            subTitle={howItWorksData.subTitle}
            type={type as 'default' | 'alternative'}
          />
        ) : null}

        <div className="divisor-container">
          <div className="divisor" />
        </div>

        {otherProducts.length > 1 ? (
          <div role="region" className="other-products">
            <h2>{detailLocale.other_products}</h2>

            <div className="other-products-list">
              {otherProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  home={home}
                  locale={locale}
                  product={product}
                  hasAction
                />
              ))}
            </div>

            {home.productList.productListingPage ? (
              <a href={mountUrl(`${pathLang}produtos`)}>
                <button>{home.productList.productListingPageCta}</button>
              </a>
            ) : null}
          </div>
        ) : null}
      </article>
    </DefaultLayout>
  );
};

export default ProductDetails;
